export default class esquecisenhaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    envia_email(login, path) {
        return this._http.post('auth/esqueci-senha', {
            login: login,
            path: path,
            tp_origem: 1
        });
    }
    altera_senha(password, queryToken) {
        return this._http.post('auth/altera-senha', {
            senha: password,
            token: queryToken
        });
    }
}
