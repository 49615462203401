<template>
    <v-card
        fluid
        fill-height
        id="principal"
        :style="{
            marginTop: '-50px',
            height: '100vh',
            width: '100vw',
            backgroundImage:
                'url(' + require('@/assets/bg-home-up-bvs.png') + ')',
            backgroundRepeat: 'round'
        }"
    >
        <v-col cols="10" sm="10" md="10" lg="4" class="ma-auto">
            <v-card class="mt-10" color="rgb(255, 255, 255, 0.95)">
                <v-col cols="8" sm="4" md="4" lg="8" class="ma-auto pt-10">
                    <v-img
                        align-center
                        justify-center
                        :src="logo"
                        alt="logo-recuperi"
                        class=""
                    />
                </v-col>
                <v-col cols="12" lg="10" class="ma-auto">
                    <v-card-text>
                        <v-form
                            ref="form"
                            v-model="valid"
                            @keyup.native.enter="checkformSubmit()"
                        >
                            <v-text-field
                                prepend-inner-icon="person"
                                name="email"
                                label="Digite seu e-mail"
                                type="email"
                                v-model="email"
                                :rules="emailRules"
                                required
                                small
                                solo
                                data-cy="signinEmailField"
                            ></v-text-field>
                            <v-text-field
                                prepend-inner-icon="lock"
                                name="password"
                                label="Digite sua senha"
                                :type="show1 ? 'text' : 'password'"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show1 = !show1"
                                data-cy="signinPasswordField"
                                v-model="password"
                                :rules="passwordRules"
                                small
                                solo
                                required
                            ></v-text-field>
                            <v-btn
                                text
                                x-small
                                dense
                                @click="dialogRecuperar = !dialogRecuperar"
                                class="grey--text mt-4"
                                >Esqueceu a senha?</v-btn
                            >
                            <v-checkbox
                                v-model="checkboxTermos"
                                x-small
                                required
                            >
                                <template v-slot:label>
                                    <div>
                                        Declaro estar de acordo com os
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://recuperi.com.br/institucional/termos-de-uso"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Termos de Uso
                                                </a>
                                            </template>
                                            Termos de uso
                                        </v-tooltip>
                                        ,
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://recuperi.com.br/institucional/politica-de-privacidade"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Política de Privacidade
                                                </a>
                                            </template>
                                            Política de Privacidade
                                        </v-tooltip>
                                        e
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://recuperi.com.br/institucional/codigo-de-etica"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Código de Ética
                                                </a>
                                            </template>
                                            Código de Ética
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            :color="primaryColorFont"
                            :disabled="checkForm()"
                            @click="submit"
                            class="white--text"
                            block
                            data-cy="signinSubmitBtn"
                            :loading="load_login"
                        >
                            Entrar
                            <v-icon class="ml-1">chevron_right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-card>
        </v-col>
        <v-dialog v-model="dialogRecuperar" max-width="400">
            <v-card>
                <v-card-text
                    v-if="this.email == '' || !/.+@.+/.test(this.email)"
                    class="pt-5 mb-n10"
                    >Informe um e-mail válido</v-card-text
                >
                <v-card-text v-else class="pt-5 mb-n10"
                    >Um e-mail será enviado para {{ email }}</v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="this.email != '' && /.+@.+/.test(this.email)"
                        color="green darken-1"
                        text
                        @click="enviaEmail(email)"
                        >Enviar</v-btn
                    >
                    <v-btn
                        color="red"
                        text
                        @click="dialogRecuperar = !dialogRecuperar"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="'red white--text'"
            :mensagemSnackbar="'E-mail ou senha incorreto.'"
            @fecharSnackbar="snackbar = false"
        />
    </v-card>
</template>

<script>
import router from '@/router';
import esquecisenhaService from '@/services/esquecisenhaService';
import Vue from 'vue';

export default {
    name: 'Login',
    data() {
        return {
            show1: false,
            logo: require('@/assets/logo_recuperi_azul_escura_parceiro_boavista.png'),
            valid: false,
            email: '',
            load_login: false,
            checkboxTermos: false,
            password: '',
            dialogRecuperar: false,
            snackbar: false,
            primaryColorFont: '#005a97',
            emailRules: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v => v.length >= 2 || 'A senha deve ter 2 ou mais caracteres'
            ]
        };
    },
    mounted() {
        this.primaryColorFont = '#005a97';
        this.$vuetify.theme.themes.light.primary = '#005a97';
    },
    methods: {
        submit() {
            this.load_login = true;
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch('userLogin', {
                        email: this.email,
                        password: this.password
                    })
                    .then(
                        response => {
                            console.log('Bem-vindo a recuperi!', response.url);

                            router.push('/');
                            window.location.reload();
                        },
                        error => {
                            console.error(
                                'Got nothing from server. Prompt user to check internet connection and try again' +
                                    error
                            );
                            this.snackbar = true;
                            this.load_login = false;
                        }
                    );
            }
        },
        enviaEmail(email) {
            return new Promise((resolve, reject) => {
                try {
                    this.dialogRecuperar = false;
                    let service = new esquecisenhaService(
                        Vue.http,
                        this.$store
                    );
                    service.envia_email(email, window.location.origin).then(
                        response => {
                            console.log(response.body);
                            console.log(window.location.origin);
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    console.log(e);
                    reject();
                }
            });
        },
        checkForm() {
            if (this.valid && this.checkboxTermos) {
                return false;
            }
            return true;
        },
        checkformSubmit() {
            const check = this.checkForm();
            if (!check) {
                this.submit();
            }
        }
    }
};
</script>
